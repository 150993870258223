*{
    margin: 0;
    padding: 0;
}

.body{
    overflow-x: hidden;
}

.header1{
    display: flex;
    height: 48px;
    background-color: black;
    padding-left: 112px;
}

.tele{
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.icon{
    padding-top: 3px;
}

.number{
    color: #ffff;
    font-family: 'Inter';
    padding-left: 10px;
    font-size: 12px;
    padding-top: 3px;
    font-weight: 500;
}

.get{
    color: #ffff;
    padding-left: 341px;
    padding-top: 15px;
    font-family: 'Inter';
    gap: 24px;
    font-size: 12px;
}

.slash{
    color: #ffff;
    padding-left: 24px;
    font-family: 'Inter';
    padding-top: 10px;
    height: 35px;
}

.shop p{
    color: yellow;
    text-decoration: none;
    font-size: 'Inter';
    font-size: 12px;
    padding-left: 30px;
    padding-top: 15px;
}

.shop a{
    text-decoration: none;
}

.left{
    display: flex;
    padding-top: 15px;
    margin-left: 40px;
}

.e{
    padding-top: 2px;
    padding-left: 180px;
    font-weight: 600;
    font-size: 12px;
    padding-right: 5px;
    align-items: center;
    font-family: 'Inter';
    color: #667185;
    width: 16px;
}

.flag{
    width: 20px;
    height: 20px;
    padding-left: 60px;
}

.n{
    padding-left: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17.4px;
    font-family: 'Inter';
    color: #667185;
    gap: 8px;
    width: 16px;
}

.ena1{
    height: 16px;
    width: 16px;
    background-color: transparent;
    color: #98A2B3;
    border-color: transparent;
}

.ena2{
    height: 16px;
    width: 16px;
    padding-left: 40px;
    background-color: transparent;
    color:#98A2B3;
    border-color: transparent;
}

.vector{
    display: none;
}

.navbar{
    display: flex;
}

.iconm{
    height: 43.74px;
    width: 42.74px;
    padding-top: 5px;
    padding-left: 60px;
    color: green;
}

.imagemenu{
    display: none;
}

.image{
    height: 43.74px;
    width: 42.74px;
}

.topmenu{
    display: flex;
    padding-top: 50px;
}

.com{
    display: flex;
    padding-left: 40px;
}
.menu{
    padding-top: 25px;
    padding-left: 120px;
    text-decoration: none;
}

.menu ul{
    color: black;
    text-decoration:none;
    display: flex;
    gap: 32px;
    align-items: center;
    list-style-type: none;
    font-family: 'Inter';
}

.menu a{
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #101928;
    font-family: 'Inter';
}

.contain{
    position: relative;
    padding-top: 15px;
    padding-left: 50px;
}

.srch1{
    width: 16px;
    height: 16px;
    position: absolute;
    top: 26px;
    left: 58px;
}

.srch2{
    background-color:transparent;
    width: 100%;
    font-family: 'Inter';
    padding: 10px 15px 10px 27px;
    border: 1px solid #D0D5DD;
    border-radius: 6px;
    color: #98A2B3;
    font-size: 14px;
    font-weight: 400;
    outline: 0;
}

.love{
    margin-top: -55px;
    margin-left: 780px;
}

.h{
    padding-top: 50px;
    height: 20px;
    width: 22px;
    padding: 24px 32px;
}

.i{
    height: 20px;
    width: 22px;
    top: 2px;
    left: 1px;
    padding: 24px 0px; 
}

.cn{
    margin-top: -40px;
    padding-left: 155px;
    text-align: center;
    border: green;
    cursor: pointer;
    text-decoration: none;
}

.cn1{
    width: 91px;
    height: 55px;
    background-color: #ffffff;
    padding: 10px 20px;
    border: 1.5px solid #2F945C;
    border-radius: 6px;
    color: #2F945C;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Inter';
}

.cn2{
    height: 56px;
    border-radius: 10px;
    border: 1.5px solid white;
    padding: 21px 24px;
    background-color: green;
    cursor: pointer;
    color: white;
    font-family: 'Inter';
}

.ham{
    display: none;
}

.frameone{
    width: 87%;
    padding-left: 100px;
    padding-top: 10px;
}

.brand{
    padding-left: 100px;
    padding-top: 60px;
    font-family: 'Inter';
}

.design{
    width: 85%;
    padding-top: 20px;
    padding-left: 95px;
}

.ca{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.view{
    display: flex;
    margin-top: 90px;
}

.ca h2{
    padding-left: 100px;
    padding-top: 80px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 28px;
}

.ca h3{
    margin-right: 5px;
    font-family: 'Inter';
    color: #2f945c;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
}

.add{
    margin-right: 75px;
    height: 20px;
    width: 20px;
}

.cate{
    width: 100%;
    padding-top: 10px;
    display: flex;
}

.cate1{
    width: 81.9%;
    padding-left: 100px;
    padding-top: 20px;
}

.cate2{
    width: 95%;
    padding-top: 20px;
}

.cate3{
    width: 95%;
    padding-top: 20px;
}

.we h2{
    font-family: 'Inter';
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    padding-left: 100px;
    padding-top: 80px;
}

.serve{
    width: 100%;
    display: flex;
    padding-left: 100px;
    padding-top: 40px;
    gap: 55px;
}

.sent{
    display: none;
}

.servea h3{
    padding-top: 10px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}

.servea p{
    width: 250px;
    padding-top: 10px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
}

.shop2{
    padding-top: 50px;
    padding-left: 100px;
    width: 86%;
}

.brand2{
    padding-left: 100px;
    padding-top: 60px;
    font-family: 'Inter';
    display: flex;
    justify-content: space-between;
}

.see{
    margin-right: 90px;
}

.pop{
    height: 395px;
    display: flex;
    padding-top: 40px;
    padding-left: 110px;
    gap: 10px;
}

.price{
    display: flex;
}

.popi{
    width: 243px;
}

.price p{
    padding-right: 50px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 10px;
    padding-top: 5px;
}

.pp{
    padding-left: 53px;
}

.price span{
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    line-height: 17.4px;
}

.star{
    padding-top: 20px;
}

.addto{
    padding-top: 10px;
}

.popnot{
    display: none;
}

.shop3{
    padding-top: 10px;
    padding-left: 100px;
    width: 86%;
}


.footer{
    padding-top: 100px;
    padding-left: 100px;
}

.footer p{
    padding-top: 12px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    color: #667185;
}

.footer h1{
    padding-top: 8px;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 28px;
}

.divider{
    padding-top: 64px;
    height: 1px;
    padding-left: 100px;
    width: 85%;
}

.footer2{
    padding-top: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 100px;
    margin-bottom: 40px;
}

.footer2 p{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
    color: #98A2B3;
}

.m2{
    margin-right: 200px;
    height: 15px;
    width: 15px;
}

@media(max-width:992px){
body{
    padding: 0;
    margin: 16px;
    width: 90%;
    position: relative;
}


.header1{
    display: none;
}

.menu{
    display: none;
}

.contain{
    display: none;
}

.com{
    display: none;
}

.navbar{
    width: 100%;
}

.iconm{
    display: flex;
    height: 24px;
    width: 24px;
    padding: 0;
    margin-top: 5px;
    gap: 250px;
}

.vector{
    display: flex;
    height: 20px;
    width: 18px;
    margin-top: 5px;
    margin-right: 0 !important;
    margin-left: auto !important;
}

.x{
    height: 30px;
    width: 55px;
}

.visible{
    margin-top: 21px;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    z-index: 1000;
    position: absolute;
    background-color: white;
    color: black;
    top: 20px;
    right: -1px;
    border-radius: 5px;

    /* transition: opacity 3s ease 8s, transform 3s ease 6s;
    transition-delay: 3s; */
}

.visible a{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: black;
}

.mob1{
    margin-top: 5px;
    margin-bottom: 10px;
    text-decoration: none;
    font-family: 'Inter';
    font-size: 20px;
}

.mob1 ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.mob2{
    width: 60%;
    margin-right: 20px;
}

.srch1{
    top: 12px;
    left: 35px;
}

.mob3{
    height: 20%;
    margin-top: 2px;
}

.mob4{
    margin-left: -30px;
    margin-top: -25px;
    display: flex;
    flex-direction: column;
}

.i{
    margin-top: -35px;
    margin-left: 30px;
}

.mob5{
    margin-top: 2px;
    display: flex;
    flex-direction: column;
}

.cn1{
    height: 30px;
    margin-top: -10px;
    padding-top: 4px;
}

.cn2{
    margin-top: 5px;
    height: 30px;
    width: 90px;
    padding-top: 4px;
    text-wrap: nowrap;
}

.frameone{
    padding: 0;
    width: 100%;
    margin-top: 15px;
}

.brand{
    padding: 0;
    margin-top: 20px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 10px;
    line-height: 11.78px;
}

.design{
    padding: 0;
    width: 100%;
    margin-top: 15px;
}

.ca{
    width: 98%;
    padding: 0;
    margin-top: 25px;
}

.view{
    margin-top: 0px;
}

.ca h2{
    padding: 0;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    align-items: center;
    line-height: 11.78px;
}

.ca h3{
    padding: 0;
    margin-right: 5px;
    font-size: 10px;
}

.add{
    padding: 0;
    margin-right: 0;
    height: 12px;
    width: 12px;
}

.cate{
    padding: 0;
    margin-top: 10px;
}

.cate1{
    padding: 0;
    width: 96%;
}

.cate2{
    padding: 0;
}

.cate3{
    margin-top: 5px;
    padding: 0;
}

.we{
    padding: 0;
}

.we h2{
    margin-top: 25px;
    padding: 0;
    width: 100%;
    font-family: 'Inter';
    font-size: 14px;
    line-height: 20px;
}

.serve{
    display: none;
}

.sent{
    width: 100%;
    margin-top: 10px;
    padding: 0;
    display: flex;
    gap: 5%;
}

.sent1 img{
    margin-top: 15px;
}

.sent1 h3{
    font-family: 'Inter';
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
}

.sent1 p{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.shop2{
    margin-top: 30px;
    width: 100%;
    padding: 0;
}

.brand2{
    padding: 0;
}

.brand2 h2{
    margin-top: 30px;
    padding: 0;
    font-family: 'inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 11px;
}

.see{
    margin-top: 30px;
    margin-right: 0;
    height: 12px;
    width: 12px;
}

.pop{
    margin-top: 10px;
    width: 100%;
    display: grid;
    padding-top: 0;
    padding-left: 0;
    grid-template-columns: 1fr 1fr;
}

.popi{
    padding: 0;
    height: 120px;
    width: 150px;
}
.pop1{
    width: 0;
    padding: 0;
}

.pop1 p{
    text-wrap: nowrap;
    font-family: 'Inter';
    font-size: 18px;
}

.price{
    padding: 0;
    margin-right: 0;
    gap: 10px;
}

.price p{
    padding-right: 0;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 10px;
    text-wrap: nowrap;
}

.pp{
    padding-left: 0;
}

.price span{
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    line-height: 17.4px;
}


.star{
    margin-top: -10px;
}

.addto{
    margin-bottom: 15px;
    margin-top: -10px;
}

.shop3{
    margin-top: 155px;
    width: 100%;
    padding: 0;
}

.footer{
    padding: 0;
    margin-top: 30px;
}

.footer h1{
    font-family: 'Inter';
    font-weight: 700;
    font-size: 14px;
}

.footer p{
    font-family: 'Inter';
    font-size: 8px;
}

.divider{
    padding: 0;
    height: 5px;
    width: 100%;
}

.footer2{
    padding: 0;
}

.media{
    margin-top: 9px;
    padding: 0;
    width: 60px;
    height: 8px;
}

.footer2 p{
    margin-top: 10px;
    padding: 0;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 8px;
}

.m2{
    display: none;
} 

}